import React, {useEffect, useRef, useState} from 'react';
import MyAppBar from "../components/myAppBar";
import Vacancy from "../wrapperComponents/vacancy";
import VacancyBody from "../wrapperComponents/vacancyBody";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";
import Services from "../wrapperComponents/services";
import AboutUs from "../wrapperComponents/aboutUs";
import LoginDialog from "../wrapperComponents/loginDialog";
import Favorites from "../wrapperComponents/favorites";
import Subscriptions from "../wrapperComponents/subscriptions";
import axios from "axios";
import apiUrls from "../apiUrls";
import {Alert, Box, CircularProgress, Grid, Snackbar, useMediaQuery, useTheme} from "@mui/material";
import {words} from "../words";
import AdminDialog from "../wrapperComponents/adminDialog";
import {changeUtilsLang, setDocDesc, setDocTitle} from "../utils/Utils";
import RekPopUp from "../wrapperComponents/RekPopUp";
import Blog from "../wrapperComponents/blog";
import BlogMain from "../wrapperComponents/blogMain";
import DocsPage from "../wrapperComponents/docsPage";
import CvAppMain from "../wrapperComponents/cvApp/cvAppMain";
import FormPage from "../wrapperComponents/formPage";
import NavigationBar from "../components/navigationBar";
import {apiGetUserInfo} from "../utils/apiInterface";
import Adminograf from "../adminograf/Adminograf";
import FeedbackButton from "../components/FeedbackButton";

const Main = (props) => {
    const isMobile = window.screen.width <= 700
    const mainConfig = useSelector(state => state.mainConfig)
    // const userConfig = useSelector(state => state.userConfig)
    const dispatcher = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const [wordsWithLang, setWordsWithLang] = useState(words);
    const isAdmin = window.location.pathname.includes("adminograf");
    const [feedbackLang, setFeedbackLang] = useState("en")
    useEffect(() => {
            switch (window.localStorage.getItem("pageLang") ? window.localStorage.getItem("pageLang") : "az") {
                case "az":
                    import("../words").then(module => {
                        setFeedbackLang("az")
                        setWordsWithLang(module.words);
                    });
                    break;
                case "ru":
                    import("../words-ru").then(module => {
                        setFeedbackLang("ru")
                        setWordsWithLang(module.words);
                    });
                    break;

                case "en":
                    import("../words-en").then(module => {
                        setFeedbackLang("en")
                        setWordsWithLang(module.words);
                    });
                    break;
                case "tr":
                    import("../words-tr").then(module => {
                        setFeedbackLang("tr")
                        setWordsWithLang(module.words);
                    });
                    break;
                default:
                    break;

            }

            if (window.location.pathname.includes("blog") || window.location.pathname.includes("cv")) {
                console.log("lang = " + window.location.pathname)
                if (window.location.pathname.includes("blog/az/") || window.location.pathname.includes("cv/az")) {
                    window.localStorage.setItem("pageLang","az")

                    setFeedbackLang("az")
                    import("../words").then(module => {
                        setWordsWithLang(module.words);
                    });
                } else if (window.location.pathname.includes("blog/en/") || window.location.pathname.includes("cv/en")) {
                    window.localStorage.setItem("pageLang","en")

                    setFeedbackLang("en")
                    import("../words-en").then(module => {
                        setWordsWithLang(module.words);
                    });
                } else if (window.location.pathname.includes("blog/ru/") || window.location.pathname.includes("cv/ru")) {
                    window.localStorage.setItem("pageLang","ru")

                    setFeedbackLang("ru")
                    import("../words-ru").then(module => {
                        setWordsWithLang(module.words);
                    });}
                else if (window.location.pathname.includes("blog/tr/") || window.location.pathname.includes("cv/tr")) {
                        window.localStorage.setItem("pageLang","tr")
                        setFeedbackLang("tr")
                        import("../words-tr").then(module => {
                            setWordsWithLang(module.words);
                        });
                } else {
                    setFeedbackLang("az")
                    import("../words").then(module => {
                        setWordsWithLang(module.words);
                    });
                }
            }
            changeUtilsLang()

        }
        ,
        []
    )


    const isBot = () => {
        if (window.navigator.userAgent.includes("render") || window.navigator.userAgent.includes("bot") || window.navigator.userAgent.includes("google") || window.navigator.userAgent.includes("linkedin") || window.navigator.userAgent.includes("house")) {
            return true;
        }
        return false
    }
    const importPage = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const [randomNum, setRandomNum] = useState(Math.floor(Math.random() * (3 - 1 + 1) + 1))
    // const importantStyle = {
    //     display: importPage && !isBot()?"block":"none",
    //     margin: 10,
    //     width: "200px",
    //     height: "600px",
    //     // backgroundColor: "lightgray",
    //     backgroundImage: "url('/img/sideRek"+randomNum+".gif')",
    //     backgroundSize: "cover",
    //     position: "fixed",
    //     left: useMediaQuery(theme => theme.breakpoints.down('xl'))?"1%":"3%",
    //     top: "350px",
    //     float: "left",
    // }
    // const importantStyle2 = {
    //     display: importPage && !isBot()?"block":"none",
    //     margin: 10,
    //     width: "200px",
    //     height: "600px",
    //     // backgroundColor: "lightgray",
    //     backgroundImage: "url('/img/sideRek"+randomNum+".gif')",
    //     backgroundSize: "cover",
    // position: "fixed",
    //     right: useMediaQuery(theme => theme.breakpoints.down('xl'))?"1%":"3%",
    //     top: "350px",
    //     float: "right",
    // }
    const lad = useRef()
    const rad = useRef()


    document.addEventListener("scroll", () => {
        if (window.scrollY > 300 && lad.current) {
            lad.current.style.top = "50px"
            rad.current.style.top = "50px"
        } else if (lad.current) {
            lad.current.style.top = "350px"
            rad.current.style.top = "350px"
        }
    })

    const importantStyle3 = {
        display: "block",
        height: "100px",
        width: "100%",
        // backgroundColor: "lightgray",
        // backgroundImage: "url('/img/middRek1.gif')",
        // backgroundSize: "contain",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        margin: "10px 0px"
    };

    const setCategories = () => {
        if (mainConfig.categories.length < 1) {
            setDocTitle(wordsWithLang.general.documentTitle)
            setDocDesc(wordsWithLang.general.documentDesc)
            axios.get(apiUrls.baseApiUrl + apiUrls.getCategories).then(resp => {
                let arr = [];
                arr.push({catName: wordsWithLang.general.all, catSlug: "all"})
                resp.data.sort((a, b) => a.pop - b.pop);
                resp.data.map((item, i) => arr.push({catName: item.category, catSlug: item.slug}))
                // arr = configState.categories.concat(arr);
                dispatcher({type: "SET_CATEGORIES", payload: arr})
            })
        }
    }

    useEffect(() => {
        apiGetUserInfo(dispatcher)
    }, [])
    useEffect(setCategories, [])

    return (
        <Grid container justifyContent={"center"} alignItems={"flex-start"}>

            <NavigationBar wordsWithLang={wordsWithLang}/>
            <Grid item xs={12}>
                {//loading page
                }
                <Box
                    style={{
                        display: mainConfig.loadingAllPage, width: "100%",
                        height: "100%",
                        backgroundColor: "black",
                        position: "fixed",
                        opacity: 0.5,
                        zIndex: theme.zIndex.drawer + 999
                    }}>ss
                    <CircularProgress style={{position: "relative", left: "50%", top: "50%"}} size={70}
                                      color="primary"/>
                </Box>
                <MyAppBar wordsWithLang={wordsWithLang}/>
                <LoginDialog open={mainConfig.isLoginOpen} wordsWithLang={wordsWithLang}/>
                <RekPopUp wordsWithLang={wordsWithLang}
                          style={{display: navigator.userAgent.toString().includes("render") ? "none" : "block"}}/>
                <AdminDialog/>
                <Snackbar open={mainConfig.isSnackOpen} style={{zIndex: 99999}} autoHideDuration={3000}
                          anchorOrigin={{
                              vertical: !isMobile ? "bottom" : "top",
                              horizontal: 'left'
                          }}
                          onClose={() => {
                              dispatcher({
                                  type: "OPEN_SNACK",
                                  payload: {"isSnackOpen": false, snackType: "success", snackMessage: ""}
                              })
                          }}>
                    <Alert severity={mainConfig.snackType} sx={{width: '100%'}} variant={"filled"}>
                        {mainConfig.snackMessage}
                    </Alert>
                </Snackbar>
            </Grid>
            {/*<Grid item xs={12} >*/}
            {/*    {location.pathname.includes("/vacancy/") ?null:<Search />}*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} style={{height: "30px"}}>*/}

            {/*</Grid>*/}
            {/*<Grid item lg={2} md={2} style={{display: importPage ? "block" : "none"}}>*/}
            {/*<Box ref={lad} onClick={()=>(window.open("https://is.gd/sideRek"+randomNum))} style={importantStyle}/>*/}
            {/*<video ref={lad} style={importantStyle} onClick={()=>(window.open("https://tinyurl.com/sideRek"+randomNum))} src={"https://bakujobs.az/img/sideRek"+randomNum+".gif"} controls playsinline muted autoPlay loop></video>*/}

            {/*</Grid>*/}


            <Grid item xs={12}>
                {location.pathname.includes("/vacancy/") ?
                    <video style={importantStyle3} onClick={() => (window.open("https://bakujobs.az/cv"))}
                           src={"https://bakujobs.az/img/middRek" + 1 + ".mp4"} controls={false} playsInline
                           muted autoPlay loop></video> : null}
            </Grid>
            {/*<Grid item xs={12} style={{height: "20px"}}>*/}
            {/*</Grid>*/}

            <Grid item xs={12} sm={12} md={isAdmin ? 11 : 8} lg={isAdmin ? 11 : 8}>
                <Routes>
                    {/*<Route path="/" element={<ComingSoon/>} exact/>*/}
                    <Route path="/" element={<Vacancy wordsWithLang={wordsWithLang}/>} exact/>
                    <Route path="/cv" element={<CvAppMain wordsWithLang={wordsWithLang}/>} exact/>
                    <Route path="/cv/:lang" element={<CvAppMain wordsWithLang={wordsWithLang}/>} exact/>
                    <Route path="/vacancy/:slug/:id" element={<VacancyBody wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/services" element={<Services wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/aboutUs" element={<AboutUs wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/docs/:type" element={<DocsPage wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/company/:slug" element={<Vacancy wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/category/:slug" element={<Vacancy wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/blog/:slug" element={<Blog wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/blog/:lang/:slug" element={<Blog wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/blog" element={<BlogMain wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/forms/:id" element={<FormPage wordsWithLang={wordsWithLang}/>}/>

                    <Route path="/favorites" element={<Favorites wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/subscriptions" element={<Subscriptions wordsWithLang={wordsWithLang}/>}/>
                    <Route path="/adminograf" element={<Adminograf wordsWithLang={wordsWithLang}/>}/>
                </Routes>

            </Grid>
            <FeedbackButton lang={feedbackLang}/>
            {/*<Grid item lg={2} md={2} style={{display: importPage ? "block" : "none"}}>*/}
            {/*<Box ref={rad} onClick={()=>(window.open("https://is.gd/sideRek"+randomNum))} style={importantStyle2}/>*/}
            {/*<video ref={rad} style={importantStyle2} onClick={()=>(window.open("https://tinyurl.com/sideRek"+randomNum))} src={"https://bakujobs.az/img/sideRek"+randomNum+".gif"} controls playsinline muted autoPlay loop></video>*/}

            {/*</Grid>*/}
        </Grid>
    );
};

export default Main;
